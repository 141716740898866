<template>
  <div>
    <div class="header">
      <div>
        <div><img :src="logo" width="57%"></div>
      </div>
    </div>
    <div class="h-link">
      <div v-for="(n, index) in 5" :key="index"
        @click="goDetail(index)"
        v-longtap="(e)=>onLongPressStart(e, index)"
      >
        <img :src="require(`@/assets/image/pd${index}.jpg`)" width="100%">
        <!-- <img :src="require(`@/assets/image/pt${index}.png`)" width="100%"> -->
      </div>
    </div>
    <div class="h-footer">
      <div><img :src="require(`@/assets/image/home-footer-1.jpg`)" alt=""></div>
      <div
        v-longtap="(e)=>saveQRcode(0)"
      ><img :src="require(`@/assets/image/home-footer-2.jpg`)" alt=""></div>
      <div
        v-longtap="(e)=>saveQRcode(1)"
      ><img :src="require(`@/assets/image/home-footer-3.jpg`)" alt=""></div>
    </div>
    <div class="h-full-pic" id="pic" v-show="playerVisible"  @click="playerVisible = false">
      <pinch-zoom limitZoom="2" backgroundColor="rgba(0,0,0,0)">
          <img :src="currentImg"/>
      </pinch-zoom>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import '@/mock/api';
import PinchZoom from 'vue-pinch-zoom';

export default {
  name: 'HomeView',
  components: { PinchZoom },
  data() {
    return {
      bg: require('@/assets/image/homebg.png'),
      itemLink: require('@/assets/image/p0.png'),
      itemTitle: require('@/assets/image/pt0.png'),
      back: require('@/assets/image/back.png'),
      logo: require('@/assets/image/titlelogo.png'),
      currentImg: '',
      menu: [],
      playerVisible: false,
    }
  },
  created(){
    axios.get("/api/l1").then(response => {
      console.log(response.data)
      this.menu=response.data;
    });
  },
  methods: {
    goDetail(i) {
      // if(i==4){
      //   return;
      // }
      this.$router.push({name: 'detail', query: { id: i }});
    },
    onLongPressStart(e,index){
      console.log(e)
      console.log(index)
      this.currentImg = require(`@/assets/image/pd${index}.png`)
      this.playerVisible = true;
      let el = document.querySelector('#my-id');
      let zoom = new PinchZoom(el);
      console.log(zoom)
    },
    saveQRcode(index){
      console.log('save');
      if(navigator.userAgent == 'app/bridgestone'){
        window.android.saveQRCode(index);
      }else if(navigator.userAgent == 'ios/bridgestone'){
        window.webkit.messageHandlers.saveQRCode.postMessage({index: index});
      }
    },
  }

}
</script>
