import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/vr',
    name: 'vr',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VrView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/performance',
    name: 'performance',
    component: () => import('../views/PerformanceView.vue')
  },
  {
    path: '/te',
    name: 'te',
    component: () => import('../views/TechnicalExplanationView.vue')
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/InfoView.vue')
  },
  {
    path: '/vrpic',
    name: 'vrpic',
    component: () => import('../views/VrPicView.vue')
  },
  {
    path: '/vrlist',
    name: 'vrlist',
    component: () => import('../views/VrListView.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/TestView.vue')
  },
]
document.title = 'BRIDGESTONE'

const router = new VueRouter({
  routes
})

export default router
