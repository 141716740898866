import Mock from 'mockjs'

const l1 = [];
for (let i = 0; i < 5; i++) {
  l1.push({
    id: i,
    name: '@cname',
    image: '@normal'
  })
}
Mock.mock('/api/l1', 'get', l1)

const Random = Mock.Random  // Mock.Random 是一个工具类，用于生成各种随机数据

const deptList = [] // 用于接受生成数据的数组(部门数据)
for (let i = 0; i < 20; i++) { // 可自定义生成的个数
  const template = {
    'id|+1': i, // 数字从当前开始，后续+1
    'deptName': '@cname', // 名字为随机中文名字
    'topDept': '@cname', // 名字为随机中文名字
    'remark': Random.cparagraph(), // 随机生成中文
    'className': 'normal', // 自定义的一个固定字段，用于修改表格选中底色
    'date': Random.date() // 生成一个随机日期,可加参数定义日期格式
  }
  deptList.push(template)
}
Mock.mock('/dept/deptList', 'get', deptList) // 根据数据模板生成模拟数据

export default []
