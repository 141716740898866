<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import VConsole from 'vconsole';
export default {
  name: 'App',
  data() {
    return {
      vConsole: null,
    }
  },
  mounted() {
    let a=0;
    if(a == 1){
      this.vConsole = new VConsole({ theme: 'dark' });
    }
  },
  beforeDestroy(){
    this.vConsole.destroy();
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
